import React from 'react';
const NotFound: Page = () => (
    <main>
        <p>Sorry, page not found!</p>
    </main>
);

NotFound.Layout = false;

export default NotFound;
